import { useAuthStore } from '~~/stores/auth';

export default defineNuxtRouteMiddleware((to, from) => {
  const { $ability } = useNuxtApp();
  const auth = useAuthStore();
  if (auth.isLoggedIn) {
    const permissions = to.matched
      .map((r) => {
        return r.meta?.permission ? r.meta?.permission : null;
      })
      .filter((p) => !!p);

    const isOk =
      permissions.length !== 0
        ? permissions.reduce((p, c) => p && $ability.can(...c), true)
        : false;

    if (!isOk) {
      throw createError({
        statusCode: 401,
        message: "Vous n'êtes pas autorisé à consulter cette page !",
      });
    }
  }
});
